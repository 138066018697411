import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: justify;
  padding: 64px;

  & section {
    margin-bottom: 16px;
  }
`

export const Title = styled.h1`
  text-align: justify;
  font-weight: bold;
`

export const Subtitle = styled.h3`
  text-align: justify;
  font-weight: 400;
`