import { Container } from './styles'
export function Home() {
  return (
    <Container>
      <p className="class-1 class-justify">
    <b>Pedido de consentimento no APP.</b></p>
    <p className="class-justify class-2">
    O Ecooperativa recolhe dados de CPF e senha do utilizador para
    ativação do canal de comunicação com o servidor de dados, não
    sendo compartilhado com demais utilizadores. Os dados do utilizador
    proveniente ao seu acesso servem apenas para monitoramento de uso e
    politicas de melhoramento do aplicativo.</p>
    <p className="class-justify class-1">--------------------------------------------------------------------------------------------------------------------------</p>
    <p className="class-justify class-1">SITE
    POLITICA</p>
    <p className="class-justify class-1">Última
    modificação em outubro de 2022</p>
    <p className="class-justify class-1"><b>Sua
    privacidade, seus direitos e nosso compromisso</b></p>
    <p className="class-justify class-2">
    Usamos seus dados pessoais para criar ferramentas que ajudam a
    facilitar a experiencia do utilizador. 
    </p>
    <p className="class-justify class-1"><br/>
    
    </p>
    <p className="class-justify class-1"><b>Nossos
    princípios de proteção de privacidade</b></p>
    <p className="class-justify class-1"><i>Nós
    somos transparentes</i></p>
    <p className="class-justify class-2">
    De uma forma clara e simples, explicamos-lhe qual a utilização que
    damos aos seus dados, como e porque tratamos os seus dados e com quem
    podemos partilhar os seus dados.</p>
    <p className="class-justify class-1"><i>Nós
    criamos valor</i></p>
    <p className="class-justify class-2">
    Processamos seus dados com responsabilidade para fornecer serviços
    personalizados. Por exemplo, usamos sua geolocalização para
    oferecer serviços próximos à sua localização.</p>
    <p className="class-justify class-1"><i>Trabalhamos
    com qualidade</i></p>
    <p className="class-justify class-2">
    Nós  com segurança e você evita o preenchimento de formulários.</p>
    <p className="class-justify class-1"><i>Protegemos
    seus dados</i></p>
    <p className="class-justify class-2">
    Protegemos seus dados pessoais com os mais altos padrões de
    segurança. Não comercializamos seus dados.</p>
    <p className="class-justify class-1"><i>Processamos
    dados por um período limitado</i></p>
    <p className="class-justify class-2">
    Mantemos seus dados apenas pelo tempo necessário para simplificar a
    maneira como você usa nossos produtos. Depois disso, seus dados são
    excluídos ou anonimizados. 
    </p>
    <p className="class-justify class-1"><br/>
    
    </p>
    <p className="class-justify class-1"><b>Quais
    dados coletamos?</b></p>
    <p className="class-justify class-2">
    Quando os dados forem fornecidos diretamente por você, por meio do
    cadastro realizado pelo próprio Usuário no aplicativo. Nessa
    hipótese serão coletados dados como: nome e CPF para que possamos
    validar a sua identidade.</p>
    <p className="class-justify class-1"><br/>
    
    </p>
    <p className="class-justify class-1">De
    forma automática, quando da navegação dos usuários no aplicativo
    poderão ser coletadas informações do dispositivo, sendo 
    </p>
    <ol type="i">
      <li><p className="class-justify class-1">
      o tipo de dispositivo; 
      </p></li>
      <li><p className="class-justify class-1">
      as conexões de rede de seu dispositivo; 
      </p></li>
      <li><p className="class-justify class-1">
      o nome de seu dispositivo; 
      </p></li>
      <li><p className="class-justify class-1">
      o endereço IP do seu dispositivo; 
      </p></li>
      <li><p className="class-justify class-1">
      informações de localização geográfica; 
      </p></li>
      <li><p className="class-justify class-1">
      informações sobre aplicativos baixados em seu dispositivo; e 
      </p></li>
      <li><p className="class-justify class-1">
      Informações de localização geográfica significam informações
      que identificam com especificidade razoável sua localização
      usando, por exemplo, coordenadas de longitude e latitude obtidas por
      meio de GPS, Wi-Fi ou triangulação celular de local. Alguns de
      nossos serviços podem pedir permissão para compartilhar sua
      localização atual. 
      </p></li>
    </ol>
    <p className="class-justify class-1"><b>Como
    utilizamos?</b></p>
    <p className="class-justify class-1"><br/>
    
    </p>
    <p className="class-justify class-2">
    Dados pessoais são tratados pelo Ecoopertiva para diversas
    finalidades.</p>
    <p className="class-justify class-1">Abaixo
    apresentamos as principais hipóteses em que trataremos as suas
    informações:</p>
    <p className="class-justify class-1"><br/>
    
    </p>
    <ul>
      <li><p className="class-justify class-1">
      Para a prestação dos serviços contratados.</p></li>
      <li><p className="class-justify class-1">
      Para possibilitar o acesso e o uso dos recursos e funcionalidades do
      aplicativo do Ecoopertiva para os usuários;</p></li>
      <li><p className="class-justify class-1">
      Para o cumprimento de determinações legais, regulações de órgãos
      governamentais, autoridades fiscais, Poder Judiciário e/ou outra
      autoridade competente;</p></li>
      <li><p className="class-justify class-1">
      Para o exercício regular dos direitos do Ecoopertiva, mesmo após o
      término da relação contratual, em processos judiciais,
      administrativos ou arbitrais, como por exemplo, em ações de
      cobrança no caso de inadimplemento de obrigação contratual, nas
      respostas a ações judiciais em que o Ecoopertiva figure como parte
      interessada, ou seja, provocado pelo juízo competente a se
      manifestar sobre determinado fato;</p></li>
      <li><p className="class-justify class-1">
      Para o alcance dos interesses legítimos do Ecoopertiva, sempre no
      limite da expectativa do usuário e sem ferir seus direitos e
      liberdades fundamentais, em situações como: iniciativas de
      divulgação e envio de novidades; análise de desempenho 
      aplicativo; medição de audiência; nas análises de segurança;
      melhoria e personalização da experiência do usuário com os
      serviços disponibilizados pelo Ecoopertiva;</p></li>
      <li><p className="class-justify class-1">
      Para a condução de atividades que visam prevenir a ocorrência de
      fraudes, como para validar a autenticidade da conexão e identidade
      do usuário nas transações realizadas, comprovando, quando
      necessário, que ele é quem realmente diz ser.</p></li>
      <li><p className="class-justify class-1">
      O Ecoopertiva se compromete a apenas coletar informações
      imprescindíveis para a prestação dos serviços/produtos
      oferecidos, assim como tratar dados pessoais apenas para as
      finalidades informadas aos usuários.</p></li>
    </ul>
    <p className="class-justify class-1"><br/>
    
    </p>
    <p className="class-justify class-1"><br/>
    
    </p>
    <p className="class-justify class-1"><br/>
    
    </p>
    <p className="class-justify class-1"><b>Compartilhamento</b></p>
    <p className="class-justify class-1"><br/>
    
    </p>
    <p className="class-justify class-2">
    Para a operacionalização de produtos e serviços oferecidos pelo
    Ecoopertiva, será necessário, em algumas situações, o
    compartilhamento de dados pessoais dos usuários.</p>
    <p className="class-justify class-1"><br/>
    
    </p>
    <p className="class-justify class-1">Abaixo
    estão algumas hipóteses, em que o compartilhamento poderá ocorrer:</p>
    <ul>
      <li><p className="class-justify class-1">
      Com autoridades, entidades governamentais ou outros terceiros, para
      a proteção dos interesses do Ecoopertiva, em qualquer conflito,
      incluindo ações judiciais, processos administrativos ou arbitrais;</p></li>
      <li><p className="class-justify class-1">
      No caso de transações e alterações societárias, hipótese em
      que a transferência dos dados pessoais será necessária para a
      continuidade dos serviços;</p></li>
      <li><p className="class-justify class-1">
      Mediante ordem judicial ou pelo requerimento de autoridades
      administrativas que detenham competência legal para a sua
      requisição.</p></li>
    </ul>
    <p className="class-justify class-1"><br/>
    
    </p>
    <p className="class-justify class-3">
    Quando houver o compartilhamento dos seus dados, isso acontecerá
    para cumprir alguma finalidade específica, informada a você, e o
    Ecoopertiva adotará as medidas necessárias para assegurar que
    qualquer terceiro que receba os seus dados pessoais, garanta a eles a
    proteção adequada.</p>
    <p className="class-justify class-1"><br/>
    
    </p>
    <p className="class-justify class-1"><br/>
    
    </p>
    <p className="class-justify class-1"><br/>
    
    </p>
    <p className="class-justify class-1"><b>Segurança
    dos Dados</b></p>
    <p className="class-justify class-1"><br/>
    
    </p>
    <p className="class-justify class-2">
    O Ecoopertiva possui políticas e procedimentos internos que têm por
    objetivo zelar pela sua privacidade, garantido a segurança e a
    proteção dos seus dados pessoais. Nesse contexto, adotamos medidas
    tecnológicas, processuais e de negócios para que os seus dados
    pessoais sejam mantidos seguros e protegidos contra acessos não
    autorizados, vazamentos, fraudes e de situações acidentais ou
    ilícitas de destruição, perda, alteração, comunicação ou
    qualquer outra forma de tratamento inadequado ou ilícito, sempre à
    luz das leis e regras aplicáveis de proteção de dados e segurança
    da informação.</p>
    <p className="class-justify class-1"><br/>
    
    </p>
    <p className="class-justify class-3">
    Qualquer dado pessoal em posse do Ecoopertiva será tratado com os
    mais rígidos padrões de segurança, o que inclui a adoção de
    medidas de:</p>
    <ul>
      <li><p className="class-justify class-1">
      Políticas e processos bem estruturados de segurança da informação
      que atendem às melhores práticas de mercado.</p></li>
      <li><p className="class-justify class-1">
      Rigorosos controles de acessos lógicos e físicos a sistemas e
      informações;</p></li>
      <li><p className="class-justify class-1">
      Avançados sistemas de proteção para combate a códigos
      maliciosos, monitoração e prevenção e resposta a incidentes de
      segurança;</p></li>
      <li><p className="class-justify class-1">
      Backup e processos de continuidade de negócios e recuperação de
      desastres.</p></li>
    </ul>
    <p className="class-justify class-1"><br/>
    
    </p>
    <p className="class-justify class-3">
    Embora o Ecoopertiva adote os melhores esforços para preservar a sua
    privacidade e proteger os seus dados pessoais, nenhum tratamento de
    informações é totalmente seguro, estando sempre suscetíveis a
    ocorrência de falhas técnicas, vírus ou ações similares. Na
    remota hipótese de incidência de episódios desta natureza, o
    Ecoopertiva garantirá a adoção de todas as medidas cabíveis para
    remediar as consequências do evento, sempre garantindo a devida
    transparência a todos.</p>
    <p className="class-justify class-1"><br/>
    
    </p>
    <p className="class-justify class-1"><br/>
    
    </p>
    <p className="class-justify class-1"><br/>
    
    </p>
    <p className="class-justify class-1"><b>Armazenamento</b></p>
    <p className="class-justify class-2">
    Os dados pessoais tratados poderão ser armazenados internamente em
    sistemas da empresa ou em sites de terceiros contratados para esse
    fim, sejam eles alocados no Brasil ou no exterior.</p>
    <p className="class-justify class-2">
    Os Dados Pessoais serão armazenados de acordo com rígidos padrões
    de segurança, seguindo a diretrizes e políticas de Segurança da
    Informação do Ecoopertiva.</p>
    <p className="class-justify class-2">
    Ainda, o Ecoopertiva também adota as melhores práticas do mercado
    para o manuseio destes dados, conforme determinado em normas e
    procedimentos de segurança da informação.</p>
    <p className="class-justify class-2">
    O Ecoopertiva irá eliminar os dados pessoais tratados quando eles
    deixarem de ser úteis para os fins para os quais foram coletados, ou
    quando você solicitar a sua eliminação, exceto se houver a
    necessidade de manutenção para fins de cumprimento de obrigação
    legal ou regulatória, para o exercício de direitos em processos
    judiciais ou administrativos pela Ecoopertiva, ou demais hipóteses
    previstas na legislação aplicável.</p>
    <p className="class-justify class-1"><br/>
    
    </p>
    <p className="class-justify class-1"><br/>
    
    </p>
    <p className="class-justify class-1"><br/>
    
    </p>
    <p className="class-justify class-1"><br/>
    
    </p>
    <p className="class-justify class-1"><b>Direitos</b></p>
    <p className="class-justify class-1"><br/>
    
    </p>
    <p className="class-justify class-1">Você
    tem os seguintes direitos relativos aos seus dados pessoais:</p>
    <ul>
      <li><p className="class-justify class-1">
      Saber se tratamos algum dado pessoal seu;</p></li>
      <li><p className="class-justify class-1">
      Saber quais dados pessoais seus são tratados por nós;</p></li>
      <li><p className="class-justify class-1">
      Corrigir dados incompletos, inexatos ou desatualizados, pelos meios
      exigidos pela regulamentação específica, quando necessário;</p></li>
      <li><p className="class-justify class-1">
      Solicitar a anonimização, bloqueio ou eliminação de dados
      desnecessários, excessivos ou que, porventura, tenham sido tratados
      em desconformidade com a lei;</p></li>
      <li><p className="class-justify class-1">
      Solicitar a portabilidade dos dados a outro fornecedor de serviço
      ou produto, caso isso seja feito expressamente;</p></li>
      <li><p className="class-justify class-1">
      Solicitar a eliminação dos dados tratados com o seu consentimento;</p></li>
      <li><p className="class-justify class-1">
      Obter informações sobre as entidades públicas ou privadas com as
      quais compartilhamos os seus dados;</p></li>
      <li><p className="class-justify class-1">
      Quando a atividade de tratamento necessitar do seu consentimento,
      você pode se negar a consentir. Nesse caso, caso você nos
      solicite, lhe informaremos sobre as consequências da não
      realização de tal atividade;</p></li>
      <li><p className="class-justify class-1">
      Quando a atividade de tratamento necessitar do seu consentimento, a
      qualquer momento você poderá revogá-lo.</p></li>
    </ul>
    <p className="class-justify class-1"><br/>
    
    </p>
    <p className="class-justify class-3">
    Neste ponto, todas as requisições serão conduzidas de forma
    gratuita e submetidas a uma forma de validação de sua identidade (a
    fim de que o Ecoopertiva possa ter a certeza de direcionar o
    atendimento de requisições, exclusivamente ao real titular dos
    dados).</p>
    <p className="class-justify class-1"><br/>
    
    </p>
    <p className="class-justify class-3">
    Para exercer os seus direitos como titular de dados pessoais entre em
    contato pelo email robison@coacen.com.br</p>
    <p className="class-justify class-1"><br/>
    
    </p>
    <p className="class-justify class-1"><b>Disposições
    Gerais</b></p>
    <p className="class-justify class-1"><br/>
    
    </p>
    <p className="class-justify class-2">
    O Ecoopertiva poderá modificar este Aviso, conforme a necessidade de
    atualização das informações aqui presentes.</p>
    <p className="class-justify class-1"><br/>
    
    </p>
    <p className="class-justify class-2">
    Este documento foi elaborado com base na Lei Federal 13.709/2018 –
    Lei Geral de Proteção de Dados.</p>
    <p className="class-justify class-1"><br/>
    
    </p>
    <p className="class-justify class-2">
    Para maiores informações sobre Privacidade e Proteção de Dados
    e/ou para dirimir quaisquer dúvidas e/ou problemas relacionados a
    este Aviso de Privacidade, entre em contato com o nosso Encarregado
    pelo Tratamento de Dados Pessoais (DPO), por meio do endereço
    robison@coacen.com.br</p>
    <p className="class-justify class-1"><br/>
    
    </p>
    <p className="class-justify class-1"><i>Esta
    Política de Privacidade foi alterada pela última vez e publicada em
    nosso site em outubro de 2022.</i></p>
    </Container>
  )
}